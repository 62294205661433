import { ContextApi } from 'contexts/Localization/types'
import { PageMeta } from './types'

export const DEFAULT_META: PageMeta = {
  title: 'IndoSwap',
  description:
    'The most popular AMM on BSC by user count! Earn IDS through yield farming or win it in the Lottery, then stake it in IDS Pools to earn more tokens! Initial Farm Offerings (new token launch model pioneered by IndoSwap), NFTs, and more, on a platform you can trust.',
  image: 'https://IndoSwap.net/images/hero.png',
}

export const getCustomMeta = (path: string, t: ContextApi['t']): PageMeta => {
  let basePath
  if (path.startsWith('/swap')) {
    basePath = '/swap'
  } else if (path.startsWith('/add')) {
    basePath = '/add'
  } else if (path.startsWith('/remove')) {
    basePath = '/remove'
  } else if (path.startsWith('/teams')) {
    basePath = '/teams'
  } else if (path.startsWith('/voting/proposal') && path !== '/voting/proposal/create') {
    basePath = '/voting/proposal'
  } else if (path.startsWith('/nfts/collections')) {
    basePath = '/nfts/collections'
  } else if (path.startsWith('/nfts/profile')) {
    basePath = '/nfts/profile'
  } else if (path.startsWith('/pancake-squad')) {
    basePath = '/pancake-squad'
  } else {
    basePath = path
  }

  switch (basePath) {
    case '/':
      return {
        title: `${t('Home')} | ${t('IndoSwap')}`,
      }
    case '/swap':
      return {
        title: `${t('Exchange')} | ${t('IndoSwap')}`,
      }
    case '/add':
      return {
        title: `${t('Add Liquidity')} | ${t('IndoSwap')}`,
      }
    case '/remove':
      return {
        title: `${t('Remove Liquidity')} | ${t('IndoSwap')}`,
      }
    case '/liquidity':
      return {
        title: `${t('Liquidity')} | ${t('IndoSwap')}`,
      }
    case '/find':
      return {
        title: `${t('Import Pool')} | ${t('IndoSwap')}`,
      }
    case '/competition':
      return {
        title: `${t('Trading Battle')} | ${t('IndoSwap')}`,
      }
    case '/prediction':
      return {
        title: `${t('Prediction')} | ${t('IndoSwap')}`,
      }
    case '/prediction/leaderboard':
      return {
        title: `${t('Leaderboard')} | ${t('IndoSwap')}`,
      }
    case '/farms':
      return {
        title: `${t('Farms')} | ${t('IndoSwap')}`,
      }
    case '/farms/auction':
      return {
        title: `${t('Farm Auctions')} | ${t('IndoSwap')}`,
      }
    case '/pools':
      return {
        title: `${t('Pools')} | ${t('IndoSwap')}`,
      }
    case '/lottery':
      return {
        title: `${t('Lottery')} | ${t('IndoSwap')}`,
      }
    case '/ifo':
      return {
        title: `${t('Initial Farm Offering')} | ${t('IndoSwap')}`,
      }
    case '/teams':
      return {
        title: `${t('Leaderboard')} | ${t('IndoSwap')}`,
      }
    case '/voting':
      return {
        title: `${t('Voting')} | ${t('IndoSwap')}`,
      }
    case '/voting/proposal':
      return {
        title: `${t('Proposals')} | ${t('IndoSwap')}`,
      }
    case '/voting/proposal/create':
      return {
        title: `${t('Make a Proposal')} | ${t('IndoSwap')}`,
      }
    case '/info':
      return {
        title: `${t('Overview')} | ${t('IndoSwap Info & Analytics')}`,
        description: 'View statistics for IndoSwap exchanges.',
      }
    case '/info/pools':
      return {
        title: `${t('Pools')} | ${t('IndoSwap Info & Analytics')}`,
        description: 'View statistics for IndoSwap exchanges.',
      }
    case '/info/tokens':
      return {
        title: `${t('Tokens')} | ${t('IndoSwap Info & Analytics')}`,
        description: 'View statistics for IndoSwap exchanges.',
      }
    case '/nfts':
      return {
        title: `${t('Overview')} | ${t('IndoSwap')}`,
      }
    case '/nfts/collections':
      return {
        title: `${t('Collections')} | ${t('IndoSwap')}`,
      }
    case '/nfts/profile':
      return {
        title: `${t('Your Profile')} | ${t('IndoSwap')}`,
      }
    case '/pancake-squad':
      return {
        title: `${t('Pancake Squad')} | ${t('IndoSwap')}`,
      }
    default:
      return null
  }
}
