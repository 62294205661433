import { MenuItemsType, DropdownMenuItemType } from '@pancakeswap/uikit'
import { ContextApi } from 'contexts/Localization/types'
import { nftsBaseUrl } from 'views/Nft/market/constants'

export type ConfigMenuItemsType = MenuItemsType & { hideSubNav?: boolean }

const config: (t: ContextApi['t']) => ConfigMenuItemsType[] = (t) => [
  {
    label: t('Trade'),
    icon: 'Swap',
    href: '/swap',
    showItemsOnMobile: false,
    items: [
      {
        label: t('Exchange'),
        href: '/swap',
      },
      {
        label: t('Liquidity'),
        href: '/liquidity',
      },
    ],
  },
  {
    label: t('Earn'),
    href: '/farms',
    icon: 'Earn',
    items: [
      {
        label: t('Farms'),
        href: '/farms',
      },
      {
        label: t('Devidend'),
        href: '/pools',
      },
    ],
  }
  ,
  {
    label: t('Launchpads'),
    href: '/launchpads',
    icon: 'IFO',
    showItemsOnMobile: false,
    items: [
     
    ],
  }
  ,

 
  {
    label: t('NFT (Coming soon)'),
    href: '#',
    icon: 'Nft',
    items: [
      
    ],
  }
]

export default config
